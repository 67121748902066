.wrapper {
  display: flex;
  flex-direction: column;
  //   background-color: white;
  overflow-x: hidden;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  padding-bottom: 48px;
  @media (max-width: 650px) {
    padding-bottom: 25px;
  }
}

.main {
  padding-top: 100px;
  padding-bottom: 120px;
  padding-left: 15px;
  padding-right: 15px;

  @media (max-width: 1280px) {
    padding-top: 60px;
    padding-bottom: 120px;
  }

  @media (max-width: 550px) {
    padding-bottom: 90px;
  }

  @media (max-width: 400px) {
    padding-top: 50px;
    padding-bottom: 70px;
  }
}

.logo {
  margin-top: auto;
  margin-right: auto;
  //   margin-bottom: 48px;
  width: 100%;
  margin-left: 48px;

  @media (max-width: 400px) {
    // margin-top: 0;
    // margin-bottom: 25px;
    text-align: center;
    margin-left: auto;
    img {
      width: 120px;
      height: 40px;
    }
  }
}

//========================================================================================================================================================

.image {
  height: 400px;

  @media (max-width: 550px) {
    height: 350px;
  }

  @media (max-width: 400px) {
    height: 300px;
  }
}

.image_first {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  img {
    width: 370px;
    height: 370px;

    @media (max-width: 550px) {
      width: 300px;
      height: 300px;
    }

    @media (max-width: 400px) {
      width: 230px;
      height: 230px;
    }
  }
}

.image_last {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  animation: circle 25s infinite linear backwards;

  @keyframes circle {
    0% {
      transform: rotate(0);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  img {
    width: 330px;
    height: 330px;

    @media (max-width: 500px) {
      width: 260px;
      height: 260px;
    }

    @media (max-width: 400px) {
      width: 200px;
      height: 200px;
    }
  }
}

//========================================================================================================================================================

.text_title {
  color: #e5c787;
  text-align: center;
  font-size: 60px;
  font-weight: 600;
  line-height: 110%;

  &:not(:last-child) {
    margin-bottom: 50px;
  }

  @media (max-width: 1280px) {
    font-size: 40px;

    &:not(:last-child) {
      margin-bottom: 40px;
    }
  }

  @media (max-width: 400px) {
    font-size: 23px;
  }
}

.text_sub_title {
  color: #eaeaea;
  text-align: center;
  font-size: 40px;
  font-weight: 600;
  line-height: 120%;

  @media (max-width: 1280px) {
    font-size: 32px;
    font-weight: 700;
  }

  @media (max-width: 400px) {
    font-size: 18px;
    font-weight: 400;
  }
}
