@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:600,700&display=swap");
* {
  padding: 0px;
  margin: 0px;
  border: 0px;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}
:focus,
:active {
  outline: none;
}
a:focus,
a:active {
  outline: none;
}
html,
body {
  height: 100%;
}
body {
  word-wrap: break-word;
  word-break: break-all;
  color: #ffffff;
  background-color: #0f0f0f;
  line-height: 1;
  font-family: "Nunito Sans";
  font-size: 40px;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

input,
button,
textarea {
  font-family: "Nunito Sans";
  font-size: inherit;
  line-height: inherit;
}
button {
  cursor: pointer;
  color: inherit;
  background-color: transparent;
}
a {
  color: inherit;
  text-decoration: none;
}
ul li {
  list-style: none;
}
img {
  vertical-align: top;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: inherit;
  font-size: inherit;
}
